import { request } from '../network/request.js'

export function pkInfoList(query) {// 停车场列表
  return request({
    url: '/parking/saas/pkInfo',
    method: 'get',
    params: query
  })
}

export function findPkInfoSelect(query) {// 停车场下拉框
  return request({
    url: '/parking/saas/pkInfo/findPkInfoSelect',
    method: 'get',
    params: query
  })
}

export function findDivsionList(query) {// 查询地区编码
  return request({
    url: '/parking/saas/division/findDivsionList',
    method: 'get',
    params: query
  })
}

export function addpkInfo(data) {// 保存停车场
  return request({
    url: '/parking/saas/pkInfo',
    method: 'post',
    data: data
  })
}

export function pkDoorList(head) {// 通道列表
  return request({
    url: '/parking/saas/pkDoor',
    method: 'get',
    headers: head
  })
}

export function pkInCarsList(query, head) {// 在场车辆查询
  return request({
    url: '/parking/saas/pkInCars',
    method: 'get',
    headers: head,
    params: query
  })
}

export function delPkInCars(id, head) {// 删除在场车辆
  return request({
    url: '/parking/saas/pkInCars/' + id,
    method: 'delete',
    headers: head,
  })
}

export function userInfoKindList(query, head) {// 固定车查询
  return request({
    url: '/parking/saas/userInfoKind',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function findUserCar(query) {// 查看用户车辆
  return request({
    url: '/parking/saas/userCar/findUserCar',
    method: 'get',
    params: query
  })
}

export function pkInfoId(id) {// 根据id查询停车场
  return request({
    url: '/parking/saas/pkInfo/' + id,
    method: 'get'
  })
}

export function findByPkId(query) {// 添加或更新发票抬头
  return request({
    url: '/parking/saas/pkInvoiceTitle/findByPkId',
    method: 'get',
    params: query
  })
}

export function pkInvoiceTitle(data) {// 保存发票抬头
  return request({
    url: '/parking/saas/pkInvoiceTitle',
    method: 'post',
    data: data
  })
}

export function userInfo(query) {// 停车车主查询
  return request({
    url: '/parking/saas/userInfo',
    method: 'get',
    params: query
  })
}

export function getSysAdminCert(query) {// 停车车主查询
  return request({
    url: '/parking/saas/pkInfo/getSysAdminCert',
    method: 'get',
    params: query
  })
}

export function findByPkIdList(query) {// 根据停车场id查询
  return request({
    url: '/parking/saas/pkPayChannel/findByPkId',
    method: 'get',
    params: query
  })
}

export function pkPayChannel(data) {// 保存支付渠道
  return request({
    url: '/parking/saas/pkPayChannel',
    method: 'post',
    data: data
  })
}

export function pkPayChangeConfig(data) {// 保存转账配置
  return request({
    url: '/parking/saas/pkPayChangeConfig',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data
  })
}

export function getByPkIdAndType(query) {// 查询停车场转账配置
  return request({
    url: '/parking/saas/pkPayChangeConfig/getByPkIdAndType',
    method: 'get',
    params: query
  })
}


export function businessList(query) {// 查询商家名称列表
  return request({
    url: '/parking/saas/shopInfo/list',
    method: 'get',
    params: query
  })
}

export function getLogonVerifyCodeSmg(query, head) {
  // 获取手机登录验证码
  return request({
    url: '/parking/saas/invoice/getLogonVerifyCodeSmg',
    method: 'post',
    headers: head,
    data: query,
  })
}

export function userNeedLogonCheck(query, head) {
  // 检查用户是否需要登录
  return request({
    url: '/parking/saas/invoice/userNeedLogonCheck',
    method: 'post',
    headers: head,
    data: query,
  })
}

export function userLogon(query, head) {
  // 用户登录
  return request({
    url: '/parking/saas/invoice/userLogon',
    method: 'post',
    headers: head,
    data: query,
  })
}

export function getFaceVerifyQRCode(query, head) {
  // 获取人脸识别二维码
  return request({
    url: '/parking/saas/invoice/getFaceVerifyQRCode',
    method: 'post',
    headers: head,
    data: query,
  })
}

export function getFaceVerifyQRCodeResult(query, head) {
  // 获取人脸二维码识别结果
  return request({
    url: '/parking/saas/invoice/getFaceVerifyQRCodeResult',
    method: 'post',
    headers: head,
    data: query,
  })
}

export function reQueryResult(query, head) {
  // 重新获取开票返回结果
  return request({
    url: '/parking/saas/orderInfoInvoice/reQueryResult',
    method: 'get',
    headers: head,
    params: query,
  })
}