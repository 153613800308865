/*
 * @Author: sunfei
 * @Date: 2022-03-15 10:50:55
 * @LastEditTime: 2023-11-02 16:54:41
 * @FilePath: \cloud-platform\src\api\dictionaries.js
 * @Description:
 */
import { request } from '../network/request.js'

export function sysDictType(query) {
  // 字典码类型列表
  return request({
    url: '/parking/saas/sysDictType',
    method: 'get',
    params: query,
  })
}

export function addSysDictType(data) {
  // 保存字典码类型
  return request({
    url: '/parking/saas/sysDictType',
    method: 'post',
    data: data,
  })
}

export function delSysDictType(id) {
  // 删除字典码类型
  return request({
    url: '/parking/saas/sysDictType/' + id,
    method: 'delete',
  })
}

export function queryDictionary(query) {
  // 根据类型查询字典码
  return request({
    url: '/parking/saas/sysDictData',
    method: 'get',
    params: query,
  })
}

export function addDictionary(data) {
  // 保存字典码
  return request({
    url: '/parking/saas/sysDictData',
    method: 'post',
    data: data,
  })
}

export function delDictionary(id) {
  // 删除字典码
  return request({
    url: '/parking/saas/sysDictData/' + id,
    method: 'delete',
  })
}

export function idsysDictData(query) {
  // 根据id查询字典码类型
  return request({
    url: '/parking/saas/sysDictType/',
    method: 'get',
    params: query,
  })
}

export function sysDictData(query) {
  // 根据类型查询字典码
  return request({
    url: '/parking/saas/sysDictData',
    method: 'get',
    params: query,
  })
}

export function sysConfigParamList(query) {
  // 系统配置列表
  return request({
    url: '/parking/saas/sysConfigParam',
    method: 'get',
    params: query,
  })
}

export function addsysConfigParam(data) {
  // 保存系统配置
  return request({
    url: '/parking/saas/sysConfigParam',
    method: 'post',
    data: data,
  })
}

export function userKind(head) {
  // 查询id用户类型
  return request({
    url: '/parking/saas/userKind',
    headers: head,
    method: 'get',
  })
}

export function pkRegionList(head) {
  // 查询区域列表
  return request({
    url: '/parking/saas/pkRegion/list',
    method: 'get',
    headers: head,
  })
}
export function flush(head) {
  // 刷新字典
  return request({
    url: '/parking/saas/sysDictData/flush',
    method: 'get',
  })
}
